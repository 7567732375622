import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import {
  NMIResponseCodes,
  OrgInvoiceChild,
  OrgPaymentType,
  PaymentMethodType,
  isKeyOfNMIResponseCodes
} from "@ollie-sports/models";

import express from "express";
import _ from "lodash";
import { formatMoneyCentsToDollarCentPrettyString, getRefundAvailabilityDetails, isProduction } from "../../utils";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { translateServer } from "../../utils/serverTranslate";
import axios from "axios";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { logUnexpectedPaymentError } from "../../utils/payment-helpers";
import { nmiSDK } from "../../utils/nmiSDK";

export async function orgPayment__server__issueRefund(
  this: ServerThisContext,
  p: {
    orgPaymentId: string;
    orgId: string;
    amountCents: number;
    reason: string;
    selfAccountId: string;
  }
): Promise<{ status: "success" } | { status: "failed"; prettyFailureReason: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  try {
    const [payment, priorRefunds, org] = await Promise.all([
      h.OrgPayment.getDoc(p.orgPaymentId),
      h.OrgPaymentRefund.query({
        where: [{ orgPaymentId: ["==", p.orgPaymentId] }]
      }).then(a => a.docs),
      h.Org.getDoc(p.orgId)
    ]);

    if (payment?.type !== OrgPaymentType.invoiceDefault || !payment.nmiPaymentResponseInfo || !payment.paymentMethodSnapshot) {
      throw new Error("May only refund actual payments!");
    }

    if (!org || !org?.accounts[p.selfAccountId]?.permissions.manageFinances) {
      throw new Error("Do not have permission to issue refunds!");
    }

    if (payment.orgId !== p.orgId) {
      throw new Error("Payment id must belong to the same organization!");
    }

    const { maxPossibleRefundCents, refundWindowIsOpen } = getRefundAvailabilityDetails({
      payment,
      refunds: priorRefunds
    });

    if (p.amountCents > maxPossibleRefundCents || p.amountCents <= 0) {
      return {
        prettyFailureReason: this.translate(
          { defaultMessage: "Unable to refund more than {maxPossibleRefund}." },
          {
            maxPossibleRefund: formatMoneyCentsToDollarCentPrettyString(maxPossibleRefundCents)
          }
        ),
        status: "failed"
      };
    }

    if (!refundWindowIsOpen) {
      return {
        prettyFailureReason: this.translate({
          defaultMessage: "Unable to refund since more than a year has elapsed since this payment was processed."
        }),
        status: "failed"
      };
    }

    try {
      var refundResp = await nmiSDK.refundTransaction({
        refundingOrgId: p.orgId,
        amountCents: p.amountCents,
        transactionId: payment.nmiPaymentResponseInfo.transactionId,
        type: payment.paymentMethodSnapshot.type === PaymentMethodType.bank ? "check" : "creditcard",
        idempotencyKey: p.orgId + p.orgPaymentId + p.reason + p.amountCents + p.selfAccountId
      });
    } catch (e) {
      logUnexpectedPaymentError({ errorCode: "refund-processing-error", olliePipe, info: e, locationId: "089sdklflkewjrlk34" });
      return {
        status: "failed",
        prettyFailureReason: this.translate({
          defaultMessage:
            "Unable to process refund. If the problem persists, please contact us at support@olliesports.com and we will help resolve the issue."
        })
      };
    }

    if (refundResp.status === "success") {
      await h.OrgPaymentRefund.add({
        doc: {
          note: p.reason,
          orgId: p.orgId,
          orgInvoiceId: payment.invoiceId,
          orgPaymentId: p.orgPaymentId,
          totalAmountRefundedCents: p.amountCents,
          createdAtMS: Date.now(),
          refundedByAccountId: p.selfAccountId,
          nmiTransactionId: refundResp.transactionId,
          playerBundleId: payment.playerBundleId
        }
      });

      return { status: "success" };
    } else {
      if (refundResp.response === 3 && refundResp.responseText.includes("must be settled")) {
        return {
          status: "failed",
          prettyFailureReason: this.translate({
            defaultMessage:
              "Your refund request was declined because the payment has not settled. Please try at a later date. If the issue persists, pleace contact us at support@olliesports.com"
          })
        };
      }
      await logUnexpectedPaymentError({
        errorCode: "refund-processing-error",
        olliePipe,
        locationId: "09809234hnmasdf34",
        info: refundResp
      });
      return {
        status: "failed",
        prettyFailureReason: this.translate({
          defaultMessage:
            "Your refund request was declined. The payment may not have settled and you should retry later. If the issue persists, pleace contact us at support@olliesports.com."
        })
      };
    }
  } catch (errRaw: unknown) {
    logUnexpectedPaymentError({
      errorCode: "refund-processing-error",
      olliePipe,
      info: errRaw,
      locationId: "234090983488cjkldlk"
    });
    return {
      status: "failed",
      prettyFailureReason: this.translate({
        defaultMessage:
          "Unable to process refund. If the problem persists, please contact us at support@olliesports.com and we will help resolve the issue."
      })
    };
  }
  // SERVER_ONLY_TOGGLE
}

orgPayment__server__issueRefund.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
