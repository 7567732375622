import {
  OrgCoupon,
  OrgInvoice,
  OrgInvoiceId,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgInvoice__Registration,
  OrgPaymentInvoiceCredit,
  OrgPaymentPlan
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit, ObjectKeys, generateOrgPaymentId } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";
import shortid from "shortid";

export async function orgPayment__client__addOrgPaymentInvoiceCredits(p: {
  orgPaymentInvoiceCredits: Omit<OrgPaymentInvoiceCredit, "id" | "createdAtMS" | "groupingId">[];
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const nowMS = Date.now();

  const batchTasks: BatchTask[] = [];

  const orgInvoices = _.compact(await h.OrgInvoice.getDocs(p.orgPaymentInvoiceCredits.map(opc => opc.invoiceId)));
  const parentOrgInvoiceIds = _.compact(
    orgInvoices.map(i =>
      i.type === OrgInvoiceTypes.manualPaymentPlanInstallment || i.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
        ? i.parentOrgInvoiceId
        : null
    )
  );

  const parentOrgInvoices = _.compact(await h.OrgInvoice.getDocs(parentOrgInvoiceIds)) as (
    | OrgInvoice__Manual
    | OrgInvoice__Registration
  )[];

  //Initialize invoice updates
  const orgInvoiceUpdates: Record<
    OrgInvoiceId,
    Pick<OrgInvoice, "derivedTotalAmountPaidCentsBeforeAllFees" | "thisInvoicePaidInFullDateMS" | "amountDueCents">
  > = orgInvoices.reduce((acc, oi) => {
    acc[oi.id] = {
      derivedTotalAmountPaidCentsBeforeAllFees: oi.derivedTotalAmountPaidCentsBeforeAllFees,
      thisInvoicePaidInFullDateMS: oi.thisInvoicePaidInFullDateMS,
      amountDueCents: oi.amountDueCents
    };
    return acc;
  }, {} as Record<OrgInvoiceId, Pick<OrgInvoice, "derivedTotalAmountPaidCentsBeforeAllFees" | "thisInvoicePaidInFullDateMS" | "amountDueCents">>);

  //1. Apply all credits to related invoice update objects, (2) Add credit batch tasks
  const groupingId = shortid();
  for (let i = 0; i < p.orgPaymentInvoiceCredits.length; i++) {
    const opc = p.orgPaymentInvoiceCredits[i];
    const prevInvoiceDetails = orgInvoiceUpdates[opc.invoiceId];
    const prevPaidAmount = prevInvoiceDetails.derivedTotalAmountPaidCentsBeforeAllFees;
    const newPaidAmount = prevPaidAmount + opc.amountCents;
    orgInvoiceUpdates[opc.invoiceId] = {
      derivedTotalAmountPaidCentsBeforeAllFees: newPaidAmount,
      thisInvoicePaidInFullDateMS: prevInvoiceDetails.thisInvoicePaidInFullDateMS
        ? prevInvoiceDetails.thisInvoicePaidInFullDateMS
        : newPaidAmount >= prevInvoiceDetails.amountDueCents
        ? nowMS
        : 0,
      amountDueCents: prevInvoiceDetails.amountDueCents
    };

    const newCredit: OrgPaymentInvoiceCredit = {
      ...opc,
      createdAtMS: nowMS,
      id: generateOrgPaymentId(),
      groupingId
    };
    batchTasks.push(
      await h.OrgPayment.add(
        {
          doc: newCredit
        },
        { returnBatchTask: true }
      )
    );
  }

  // Write invoice update batch tasks
  for (let j = 0; j < ObjectKeys(orgInvoiceUpdates).length; j++) {
    const invoiceId = ObjectKeys(orgInvoiceUpdates)[j];
    batchTasks.push(
      await h.OrgInvoice.update(
        {
          id: invoiceId,
          doc: {
            derivedTotalAmountPaidCentsBeforeAllFees: orgInvoiceUpdates[invoiceId].derivedTotalAmountPaidCentsBeforeAllFees,
            thisInvoicePaidInFullDateMS: orgInvoiceUpdates[invoiceId].thisInvoicePaidInFullDateMS
          }
        },
        { returnBatchTask: true }
      )
    );
  }

  //Initialize parent invoice update objects
  const parentInvoiceUpdates = _(parentOrgInvoices)
    .keyBy(a => a.id)
    .mapValues(a => ({
      derivedTotalAmountPaidCentsIncludingChildrenInvoices: a.derivedTotalAmountPaidCentsIncludingChildrenInvoices,
      thisInvoicePaidInFullDateMS: a.thisInvoicePaidInFullDateMS
    }))
    .value();

  //Apply all relevant credits to parent invoices
  p.orgPaymentInvoiceCredits.forEach(c => {
    const invoice = orgInvoices.find(a => a.id === c.invoiceId)!;
    if (
      invoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
      invoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
    ) {
      const parentInvoice = parentOrgInvoices.find(a => a.id === invoice.parentOrgInvoiceId)!;

      if (
        invoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
        invoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
      ) {
        const parentInvoiceUpdate = parentInvoiceUpdates[invoice.parentOrgInvoiceId]!;
        const newTotalAmountPaid = parentInvoiceUpdate.derivedTotalAmountPaidCentsIncludingChildrenInvoices + c.amountCents;
        parentInvoiceUpdates[invoice.parentOrgInvoiceId].derivedTotalAmountPaidCentsIncludingChildrenInvoices =
          newTotalAmountPaid;
      }
    }
  });

  //Write parent update batch tasks
  for (let j = 0; j < ObjectKeys(parentInvoiceUpdates).length; j++) {
    const invoiceId = ObjectKeys(parentInvoiceUpdates)[j] as string;
    batchTasks.push(
      await h.OrgInvoice.update(
        {
          id: invoiceId,
          doc: {
            derivedTotalAmountPaidCentsIncludingChildrenInvoices:
              parentInvoiceUpdates[invoiceId].derivedTotalAmountPaidCentsIncludingChildrenInvoices,
            thisInvoicePaidInFullDateMS: parentInvoiceUpdates[invoiceId].thisInvoicePaidInFullDateMS
          }
        },
        { returnBatchTask: true }
      )
    );
  }

  await h._BatchRunner.executeBatch(batchTasks);
}

// i18n certified - complete
