import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { OrgInvoiceChild } from "@ollie-sports/models";

import express from "express";
import _ from "lodash";
import moment from "moment";
import { getIndividualOrgInvoiceAmountDetails, isProduction } from "../../utils";
import { orgInvoice__server__payIndividualOrgInvoiceChild } from "../orgInvoice";
import { getDefaultPaymentAccount } from "../../compute/account.compute";

export async function orgPayment__server__processScheduledOrgPayments() {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h, getAppPgPool } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();

  const startDate = moment().startOf("hour").valueOf();
  const endDate = moment().endOf("hour").valueOf();

  try {
    const [scheduledOrgInvoicePaymentPlanInstallmentsData, scheduledOrgInvoicePaymentRetriesData] = await Promise.all([
      h.OrgInvoice.query({
        where: [
          {
            autoChargeDateMS: [">=", startDate]
          },
          {
            autoChargeDateMS: ["<", endDate]
          }
        ],
        limit: 5000
      }),
      h.OrgInvoice.query({
        where: [
          {
            failedPaymentScheduledRetryMS: [">=", startDate]
          },
          {
            failedPaymentScheduledRetryMS: ["<", endDate]
          }
        ],
        limit: 5000
      })
    ]);
    const scheduledOrgInvoicePaymentPlanInstallments = scheduledOrgInvoicePaymentPlanInstallmentsData.docs as OrgInvoiceChild[];
    const scheduledOrgInvoicePaymentRetries = scheduledOrgInvoicePaymentRetriesData.docs as OrgInvoiceChild[];

    const allInvoices = [...scheduledOrgInvoicePaymentPlanInstallments, ...scheduledOrgInvoicePaymentRetries];

    if (allInvoices.length) {
      olliePipe.emitEvent({
        type: "metric-process-scheduled-payments",
        payload: { type: "success", orgInvoiceIds: allInvoices.map(a => a.id) }
      });
    }
    await Promise.all(
      allInvoices
        .filter(oi => !oi.thisInvoicePaidInFullDateMS)
        .map(async orgInvoice => {
          if (orgInvoice.thisInvoicePaidInFullDateMS) {
            // Already paid so skip it
            return;
          }
          if (
            orgInvoice.previousFailedPaymentAttemptMS &&
            orgInvoice.previousFailedPaymentAttemptMS > startDate &&
            orgInvoice.previousFailedPaymentAttemptMS < endDate
          ) {
            // Already failed within the last hour so skip it
            return;
          }

          if ((orgInvoice.numberOfFailedPaymentAttempts ?? 0) > 4) {
            // Already failed 5 times
            return;
          }
          try {
            const [accountPrivate, orgPaymentsData, orgSettings, accountSecret] = await Promise.all([
              h.AccountPrivate.getDoc(orgInvoice.accountIdScheduledToPay),
              h.OrgPayment.query({
                where: [
                  {
                    invoiceId: ["==", orgInvoice.id]
                  }
                ]
              }),
              h.OrgSettings.getDoc(orgInvoice.orgId),
              h.AccountSecret.getDoc(orgInvoice.accountIdScheduledToPay)
            ]);
            const defaultPaymentMethod = getDefaultPaymentAccount(Object.values(accountSecret?.paymentMethodsById ?? {}));
            if (!defaultPaymentMethod) {
              // Payment TODO: Throw error
              return;
            }
            const orgInvoiceDetails = getIndividualOrgInvoiceAmountDetails({
              orgInvoice: orgInvoice,
              orgPayments: orgPaymentsData.docs,
              feeDetails: orgSettings?.customFeeDetails?.[defaultPaymentMethod.type],
              paymentMethodType: defaultPaymentMethod.type
            });
            const paymentProps = {
              locale: accountPrivate?.communicationLocale ?? "en-US",
              orgInvoice,
              isAutoPayment: true,
              selfAccountId: orgInvoice.accountIdScheduledToPay,
              todayPaymentDetails: {
                baseAmountDueCents: orgInvoiceDetails.remainingAmount,
                otherFeesAmountDueCents: orgInvoiceDetails.estimatedOtherFeesAmountDueCentsOnRemainingAmount,
                lateFeeAmountDueCents: orgInvoiceDetails.lateFeesAmountDueCents
              }
            };
            let shouldRetry = false;
            try {
              const result = await orgInvoice__server__payIndividualOrgInvoiceChild(paymentProps);
              shouldRetry = result.type === "error";
            } catch (e) {
              shouldRetry = true;
            }
            if (shouldRetry) {
              await orgInvoice__server__payIndividualOrgInvoiceChild({ ...paymentProps, isImmediateRetry: true });
            }
          } catch (e) {
            olliePipe.emitEvent({ type: "process-scheduled-payment-unexpected-error", payload: { orgInvoice, error: e } });
          }
        })
    );
  } catch (e) {}
  // SERVER_ONLY_TOGGLE
}

orgPayment__server__processScheduledOrgPayments.auth = async (r: express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called directly via cron jobs in production!");
  }
};

// i18n certified - complete
